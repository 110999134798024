export const environment = {
  production: true,
  serverUrl: 'https://getquote.thinksmarterinsurance.ca',
  firebaseConfig: {
    apiKey: "AIzaSyC2BnHcP6DMM9nGPbn-etb2TMt95OBfPAc",
    authDomain: "think-smart-9e00c.firebaseapp.com",
    databaseURL: "https://think-smart-9e00c-default-rtdb.firebaseio.com",
    projectId: "think-smart-9e00c",
    storageBucket: "think-smart-9e00c.appspot.com",
    messagingSenderId: "169227166767",
    appId: "1:169227166767:web:2bd850588e321ed0b652da",
    measurementId: "G-EZJFN4WKSV"
  }
};